import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../images/mr-krabs-violin-meme.gif"

const NotFoundPage: React.FC<void> = () => (
  <Layout>
    <SEO
      title="404: Not found"
      description="Page not found"
      lang="en"
      meta={[]}
    />
    <div style={{textAlign: 'center'}}>
      <h1>NOT FOUND</h1>
      <img src={Image} alt="Let me play you a sad song on the world's smallest violin" />
      <br />
      <span style={{fontSize: '12px'}}>Let me play you a sad song on the world's smallest violin</span>
    </div>
  </Layout>
)

export default NotFoundPage
